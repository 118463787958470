import errorService from "../error.service";
import EventsTrackingService from "../events-tracking.service";
import configurationService from "./configuration.service";
import sessionService from "./session.service";
import visitorService from "./visitor.service";
import WishlistService from "../events/wishlist.service";
import loginService from "../events/login.service";
import CartService from "../events/cart.service";
import PushNotificationService from "../push-notification.service";
import { SubscriptionResponse } from "../events/subscriptionToNewsletter.service";

class PublicApiService {
  private isLogin: boolean = false;
  private eventTrackingService: EventsTrackingService | null = null;
  public cartState: any[] = [];

  private async getEventTrackingService(): Promise<EventsTrackingService> {
    if (!this.eventTrackingService) {
      const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
      if (!pobucaKey) throw new Error("Pobuca key not found.");

      const sessionId: string = sessionService.getSessionInfo()?.sessionId || sessionService.createNewSession().sessionId;
      const personId: string = visitorService.getPersonId();

      this.eventTrackingService = EventsTrackingService.getInstance(pobucaKey, sessionId, personId);
    }
    return this.eventTrackingService;
  }

  public isLoginEventProcessed(): boolean {
    return this.isLogin;
  }

  public async onLogin(contactInfo: string): Promise<void> {
    try {
      await loginService.initialize();
      await loginService.handleUserLogin(contactInfo || "");
      this.isLogin = true;

      const contactId: string | null = loginService.getContactId();
      if (contactId) await PushNotificationService.updateSubscriptionWithContactId(contactId);

    } catch (error: unknown) {
      errorService.errorHandler(`Error in login: ${error}`);
    }
  }

  public async onLogout(): Promise<void> {
    try {
      await loginService.initialize();
      await loginService.setLoggedOutPerson();

      const cartService: CartService = CartService.getInstance();
      await cartService.emptyCart();
      this.isLogin = false;
    } catch (error: unknown) {
      errorService.errorHandler(`Error in logout: ${error}`);
    }
  }

  public async onAddToCart(productDetails: any): Promise<void> {
    try {
      const cartService: CartService = CartService.getInstance();
      await cartService.handleAddToCart(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating cart: ${error}`);
    }
  }

  public async onReduceProductFromCart(productDetails: any): Promise<void> {
    try {
      const cartService: CartService = CartService.getInstance();
      await cartService.handleReduceProductFromCart(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating cart: ${error}`);
    }
  }

  public async onDeleteEntireProductFromCart(productDetails: any): Promise<void> {
    try {
      const cartService: CartService = CartService.getInstance();
      await cartService.handleDeleteEntireProductFromCart(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating cart: ${error}`);
    }
  }

  public async onViewItem(productDetails: any): Promise<void> {
    try {
      const eventService: EventsTrackingService = await this.getEventTrackingService();
      await eventService.trackViewItem(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking product: ${error}`);
    }
  };

  public async onPurchase(cart: any): Promise<void> {
    try {
      const cartService: CartService = CartService.getInstance();
      await cartService.handlePurchaseCart(cart);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking purchase: ${error}`);
    }
  };

  public async onAddToWishlist(productDetails: any): Promise<void> {
    try {
      const wishlistService: WishlistService = WishlistService.getInstance()
      await wishlistService.handleAddToWishlist(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating wishlist: ${error}`);
    }
  }

  public async onRemoveFromWishlist(productDetails: any): Promise<void> {
    try {
      const wishlistService: WishlistService = WishlistService.getInstance()
      await wishlistService.handleRemoveFromWishlist(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating wishlist: ${error}`);
    }
  }

  public async onViewItemList(categoryDetails: any): Promise<void> {
    try {
      const eventService: EventsTrackingService = await this.getEventTrackingService();
      await eventService.trackViewItemList(categoryDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking category view: ${error}`);
    }
  }

  public async onSearch(searchDetails: any): Promise<void> {
    try {
      const eventService: EventsTrackingService = await this.getEventTrackingService();
      await eventService.trackSearch(searchDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking search: ${error}`);
    }
  };

  public async onRetieveCurrentCart(productDetails: any): Promise<void> {
    try {
      const cartService: CartService = CartService.getInstance();
      await cartService.handleRetrieveCurrentCart(productDetails);
    } catch (error: unknown) {
      errorService.errorHandler(`Error retrieving current cart: ${error}`);
    }
  }

  public async onSubscribeToNewsletter(email: string): Promise<SubscriptionResponse> {
    try {
      const eventService: EventsTrackingService = await this.getEventTrackingService();
      const subscriptionResponse: SubscriptionResponse = await eventService.subscribeToNewsletter(email);
      return subscriptionResponse;
    } catch (error: unknown) {
      errorService.errorHandler(`Error on Subscribe To Newsletter:${error}`);
      return {
        success: false,
        message: "An unexpected error occurred.",
      };
    }
  }

}
export default new PublicApiService();
