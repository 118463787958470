export function getAllUrlParams(): Record<string, string> | undefined {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
  const queryParams: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return Object.keys(queryParams).length > 0 ? queryParams : undefined;
}
