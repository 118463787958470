import { Popup } from "../../models/interfaces/Popup";
import { SessionInfo } from "../../models/interfaces/SessionInfo";
import apiService from "../api.service";
import errorService from "../error.service";
import configurationService from "../initialization/configuration.service";
import sessionService from "../initialization/session.service";
import visitorService from "../initialization/visitor.service";
import displayPopupService from "./display-popup.service";

class PopupPreviewService {
  private static instance: PopupPreviewService;
  private isPreviewShown: boolean = false;

  public static getInstance(): PopupPreviewService {
    if (!PopupPreviewService.instance) {
      PopupPreviewService.instance = new PopupPreviewService();
    }
    return PopupPreviewService.instance;
  }

  public getQueryParam(param: string): string | null {
    return new URLSearchParams(window.location.search).get(param);
  }

  public async previewPopup(popupId: string): Promise<void> {
    if (this.isPreviewShown) return;
    this.isPreviewShown = true;

    try {
      const apiData: any = await this.fetchPopupData(popupId);
      if (!apiData.length) return;

      const popupData: any = apiData[0] || apiData; // Handle the nested structure
      const { sessionId, personId, personType } = await this.getUserSessionDetails();

      setTimeout(() => {
        displayPopupService.displayPopup(popupData, personId, sessionId, personType);
      }, 2000);
    } catch (error: unknown) {
      errorService.errorHandler(`Error displaying popup preview: ${error} `);
    }
  }

  private async fetchPopupData(popupId: string): Promise<Popup | null> {
    try {
      const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
      const headers: any = { "x-functions-key": pobucaKey };
      const response: Response = await apiService.get(`PopupPreview/${popupId}`, headers);
      const data: any = await response.json();

      return data || null;
    } catch (error: unknown) {
      errorService.errorHandler(`Failed to parse JSON response for popup ID ${popupId}: ${error} `);
      return null;
    }
  }

  private async getUserSessionDetails(): Promise<{ sessionId: string; personId: string; personType: string }> {
    const personId: string = visitorService.getPersonId();
    const personType: string = visitorService.getPersonType(false);
    const sessionInfo: SessionInfo | null = sessionService.getSessionInfo();
    const sessionId: string = sessionInfo?.sessionId || sessionService.createNewSession().sessionId;

    return { sessionId, personId, personType };
  }
}

export default PopupPreviewService.getInstance();
