class PriorityStateService {
  private static instance: PriorityStateService;
  private currentPriority: number = 1;
  private currentPriorityState: string[] = [];

  constructor() {
    if (PriorityStateService.instance) return PriorityStateService.instance;
    PriorityStateService.instance = this;
  }

  public getCurrentPriorityState(): string[] {
    return this.currentPriorityState;
  }

  public addPriorityEntry(triggerType: string, priority: number): void {
    this.currentPriorityState.push(`${triggerType}-priority-${priority}`);
    this.currentPriority += 1;
  }
}

export default new PriorityStateService();
