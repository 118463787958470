import { TrackingEvents } from "../models/interfaces/TrackingEvents";
import apiService from "./api.service";

class TrackingService {
    private xFunctionKey: string;
    private static instance: TrackingService;

    private constructor(xFunctionKey: string) {
        this.xFunctionKey = xFunctionKey;

        if (TrackingService.instance) {
            return TrackingService.instance;
        }

        TrackingService.instance = this;
    }

    public static getInstance(xFunctionKey: string): TrackingService {
        if (!TrackingService.instance) {
            TrackingService.instance = new TrackingService(xFunctionKey);
        }
        return TrackingService.instance;
    }

    public async trackEvents(eventData: TrackingEvents): Promise<TrackingEvents> {
        const response: TrackingEvents = await apiService.post("Event", { ...eventData }, {
            "x-functions-key": this.xFunctionKey,
            "Content-Type": "application/json"
        });
        return response;
    }

}

export default TrackingService;