import errorService from "../error.service";
import EventsTrackingService from "../events-tracking.service";
import configurationService from "../initialization/configuration.service";
import sessionService from "../initialization/session.service";
import visitorService from "../initialization/visitor.service";

class CartService {
  private static instance: CartService;
  private eventTrackingService: EventsTrackingService | null = null;
  private cartState: any[] = [];

  private constructor() {
    const savedCart: string | null = localStorage.getItem("pb-cart");
    this.cartState = savedCart ? JSON.parse(savedCart) : [];
  }

  public static getInstance(): CartService {
    if (!this.instance) {
      this.instance = new CartService();
    }
    return this.instance;
  }

  private async getEventTrackingService(): Promise<EventsTrackingService> {
    if (!this.eventTrackingService) {
      const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
      if (!pobucaKey) throw new Error("Pobuca key not found.");

      const sessionId: string = sessionService.getSessionInfo()?.sessionId || sessionService.createNewSession().sessionId;
      const personId: string = visitorService.getPersonId();

      this.eventTrackingService = EventsTrackingService.getInstance(pobucaKey, sessionId, personId);
    }
    return this.eventTrackingService;
  }

  private saveToLocalStorage(): void {
    localStorage.setItem("pb-cart", JSON.stringify(this.cartState));
  }

  private normalizeItemData(item: any): any {
    return {
      item_id: item.item_id,
      ...item,
    };
  }

  public async handleAddToCart(productDetails: any): Promise<void> {
    try {
      const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
      const itemsArray: any = Array.isArray(productDetails.items) ? productDetails.items : [productDetails.items];

      itemsArray.forEach((productItem: any) => {
        const normalizedItem: any = this.normalizeItemData(productItem);
        normalizedItem.quantity = Number(normalizedItem.quantity || 1);

        if (isNaN(normalizedItem.quantity) || normalizedItem.quantity <= 0) {
          throw new Error(`Invalid quantity for item ID ${normalizedItem.item_id}`);
        }
        const existingItemIndex: number = this.cartState.findIndex(
          (cartItem) => cartItem.item_id === normalizedItem.item_id
        );

        if (existingItemIndex !== -1) {
          this.cartState[existingItemIndex].quantity += normalizedItem.quantity;
        } else {
          this.cartState.push(normalizedItem);
        }
      });

      this.saveToLocalStorage();
      const cartDetails: any = this.constructCartPayload();
      const productDetailsPayload = {
        items: itemsArray,
        value: itemsArray.reduce(
          (total: number, item: any) => total + (item.price * item.quantity),
          0
        ),
        currency: "EUR",
      };

      await eventTrackingService.trackAddToCart(
        productDetailsPayload,
        cartDetails
      );
    } catch (error: unknown) {
      errorService.errorHandler(`Error adding to cart: ${error}`);
    }
  }

  public async handleReduceProductFromCart(productDetails: any): Promise<void> {
    try {
      const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
      const itemsArray: any = Array.isArray(productDetails.items) ? productDetails.items : [productDetails.items];

      itemsArray.forEach((productItem: any) => {
        const normalizedItem: any = this.normalizeItemData(productItem);
        normalizedItem.quantity = Number(normalizedItem.quantity || 1);

        if (isNaN(normalizedItem.quantity) || normalizedItem.quantity <= 0) {
          throw new Error(`Invalid quantity for item ID ${normalizedItem.item_id}`);
        }
        const existingItemIndex: number = this.cartState.findIndex(
          (cartItem) => cartItem.item_id === normalizedItem.item_id
        );

        if (existingItemIndex !== -1) {
          const existingItem: any = this.cartState[existingItemIndex];

          if (existingItem.quantity > normalizedItem.quantity) {
            // Decrease quantity
            existingItem.quantity -= normalizedItem.quantity;
          } else {
            // Remove item from cart if quantity is <= 0
            this.cartState.splice(existingItemIndex, 1);
          }
        } else {
          console.warn(`Item ID ${normalizedItem.item_id} not found in cart.`);
        }
      });

      this.saveToLocalStorage();
      const cartDetails: any = this.constructCartPayload();
      const productDetailsPayload = {
        items: itemsArray,
        value: itemsArray.reduce(
          (total: number, item: any) => total + (item.price * item.quantity),
          0
        ),
        currency: "EUR",
      };

      await eventTrackingService.trackRemoveFromCart(
        productDetailsPayload,
        cartDetails
      );
    } catch (error: unknown) {
      errorService.errorHandler(`Error removing from cart: ${error}`);
    }
  }

  public async handleDeleteEntireProductFromCart(productDetails: any): Promise<void> {
    try {
      const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
      const itemsArray: any = Array.isArray(productDetails.items) ? productDetails.items : [productDetails.items];

      itemsArray.forEach((productItem: any) => {
        const normalizedItem: any = this.normalizeItemData(productItem);

        const existingItemIndex: number = this.cartState.findIndex(
          (cartItem) => cartItem.item_id === normalizedItem.item_id
        );

        if (existingItemIndex !== -1) {
          this.cartState.splice(existingItemIndex, 1);
        } else {
          errorService.errorHandler(`Item ID ${normalizedItem.item_id} not found in cart.`);
        }
      });

      this.saveToLocalStorage();
      const cartDetails: any = this.constructCartPayload();
      const productDetailsPayload = {
        items: itemsArray,
        value: itemsArray.reduce(
          (total: number, item: any) => total + (item.price * item.quantity),
          0
        ),
        currency: "EUR",
      };

      await eventTrackingService.trackRemoveFromCart(
        productDetailsPayload,
        cartDetails
      );
    } catch (error: unknown) {
      errorService.errorHandler(`Error removing from cart: ${error}`);
    }
  }

  public async handleRetrieveCurrentCart(cartDetails: any): Promise<void> {
    try {
      if (!cartDetails || !Array.isArray(cartDetails.items)) {
        throw new Error("Invalid cart details received");
      }
      const existingCart: string | null = localStorage.getItem("pb-cart");
      const currentCartState: any[] = existingCart ? JSON.parse(existingCart) : [];

      // Create a Map for merging cart items
      const cartMap: Map<string, any> = new Map<string, any>();
      currentCartState.forEach((item) => {
        cartMap.set(item.item_id, { ...item });
      });

      cartDetails.items.forEach((item: any) => {
        if (cartMap.has(item.item_id)) {
          // If item already exists, increase the quantity
          cartMap.get(item.item_id).quantity += item.quantity;
        } else {
          // Add a new item
          cartMap.set(item.item_id, { ...item });
        }
      });

      this.cartState = Array.from(cartMap.values());
      this.saveToLocalStorage();
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      errorService.errorHandler(`Error merging cart on login: ${errorMessage}`);
    }
  }

  private constructCartPayload(): any {
    const value: number = this.cartState.reduce(
      (total: number, item: any) => total + (item.price * item.quantity),
      0
    );

    return {
      items: this.cartState,
      currency: "EUR",
      value: value.toFixed(2),
    };
  }

  public async handlePurchaseCart(cart: any): Promise<void> {
    const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
    await eventTrackingService.trackPurchase(cart);
    this.emptyCart();
  }

  public async emptyCart(): Promise<void> {
    this.cartState = [];
    localStorage.setItem("pb-cart", JSON.stringify(this.cartState));
  }
}

export default CartService;
