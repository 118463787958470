import { Popup } from "./library/models/interfaces/Popup";
import errorService from "./library/services/error.service";
import EventsTrackingService from "./library/services/events-tracking.service";
import configurationService from "./library/services/initialization/configuration.service";
import sessionService from "./library/services/initialization/session.service";
import visitorService from "./library/services/initialization/visitor.service";
import websitesStateService from "./library/services/initialization/websites-state.service";
import FetchPopupService from "./library/services/popup/fetch-popup.service";
import PopupHandlerService from "./library/services/popup/popup-hander.service";
import ConversionEventService from "./library/services/popup/conversion-event.service";
import TrackingService from "./library/services/tracking.service";
import UrlDetectionService from "./library/services/url-detection.service";
import CookieService from "./library/services/cookies/cookies.service";
import PushNotificationService from "./library/services/push-notification.service";
import popupPreviewService from "./library/services/popup/popup-preview.service";
import DataSourceService from "./library/services/data-source.service";
import "./library/public-api";
import CookiesConsentService from "./library/services/cookies/cookies-consent.service";
import { detectDeviceType } from "./library/utilities/device.utility";
import { DeviceType } from "./library/models/types/Device";

const SESSION_REFRESH_INTERVAL: number = 60 * 60 * 1000; // 1 hour in milliseconds
let isAppInitialized: boolean = false;

window.addEventListener('DOMContentLoaded', initializeApp);

async function initializeApp(): Promise<void> {
  if (isAppInitialized) return;
  isAppInitialized = true;

  try {
    await initializeTrackingAndPopups();
    setInterval(() => sessionService.createNewSession(), SESSION_REFRESH_INTERVAL); // Session refresh every hour
    await websitesStateService.groupLocalStorage();
    await PushNotificationService.initialize();

    if (Notification.permission !== "granted") {
      CookieService.getInstance().set('pb_pushNotifications', 'disabled', 1);
      await PushNotificationService.unsubscribeFromPushService();
    }
  } catch (error: unknown) {
    errorService.errorHandler(error);
  }
}

async function initializeTrackingAndPopups(): Promise<void> {
  if (!CookiesConsentService.isTrackingAllowed() && !CookiesConsentService.isPopupsAllowed()) {
    return;
  }

  try {
    const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
    if (!pobucaKey) throw new Error('Pobuca key not found.');

    const { sessionId, personId } = await initializeUserSession();
    const trackingService: TrackingService = TrackingService.getInstance(pobucaKey);
    const cookieService: CookieService = CookieService.getInstance();

    await initializeEventTracking(sessionId, personId, pobucaKey, cookieService);
    await handlePopups(trackingService, cookieService);

    const urlDetectionService: UrlDetectionService = new UrlDetectionService();
    urlDetectionService.onUrlChange(() => {
      handlePopups(trackingService, cookieService);
    });
  } catch (error: unknown) {
    errorService.errorHandler(error);
  }
}

// Initialize session and person info
async function initializeUserSession(): Promise<{
  sessionId: string;
  personId: string;
  personType: string;
}> {
  const personId: string = visitorService.getPersonId();
  const personType: string = visitorService.getPersonType(false);
  const sessionId: string = sessionService.getSessionInfo()?.sessionId || sessionService.createNewSession().sessionId;
  return { sessionId, personId, personType };
}

// Initialize event tracking and page load tracking
async function initializeEventTracking(sessionId: string, personId: string, pobucaKey: string, cookieService: CookieService): Promise<void> {
  if (!CookiesConsentService.isTrackingAllowed()) return;

  cookieService.set('pb_trackEvents', 'enabled', 1);

  DataSourceService.getInstance();
  const eventTrackingService: EventsTrackingService = EventsTrackingService.getInstance(pobucaKey, sessionId, personId);
  await eventTrackingService.trackPageLoad(window.location.href);

  const conversionEventService: ConversionEventService = new ConversionEventService(pobucaKey);
  conversionEventService.listenForEvents(personId, sessionId, visitorService.getPersonType(false));
}

// Fetch and handle popups
async function handlePopups(trackingService: TrackingService, cookieService: CookieService): Promise<void> {
  if (!CookiesConsentService.isPopupsAllowed()) return;

  cookieService.set('pb_displayPopups', 'enabled', 1);
  try {
    const deviceType: DeviceType = detectDeviceType();
    const fetchPopupService: FetchPopupService = new FetchPopupService();
    const popupHandlerService: PopupHandlerService = new PopupHandlerService(trackingService);
    const popupData: Popup[] = await fetchPopupService.getPopups(deviceType);

    if (!popupData.length) throw new Error("No popups available.");

    popupHandlerService.handlePopupTriggers(popupData);
    fetchPopupService.setupPopupRefresh();

    //If in the url is: ?pbpreview=b669cf3b-94ad-ef11-b8e9-000d3ad84840
    const previewPopupId: string | null = popupPreviewService.getQueryParam('pbpreview');
    if (previewPopupId) await popupPreviewService.previewPopup(previewPopupId);
  } catch (error: unknown) {
    errorService.errorHandler(`Error fetching and handling popups ${error}`);
  }
}
