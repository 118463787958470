import { TrackingEvents } from "../models/interfaces/TrackingEvents";
import TrackingService from "./tracking.service";
import UrlDetectionService from "./url-detection.service";
import visitorService from "./initialization/visitor.service";
import loginService from "./events/login.service";
import errorService from "./error.service";
import fetchPopupService from "./popup/fetch-popup.service";
import FetchPopupService from "./popup/fetch-popup.service";
import PopupHandlerService from "./popup/popup-hander.service";
import { Popup } from "../models/interfaces/Popup";
import { getAllUrlParams } from "../utilities/query-params.utility";
import subscriptionService, { SubscriptionResponse } from "./events/subscriptionToNewsletter.service";
import { getDate } from "../utilities/date.utility";
import CookiesConsentService from "./cookies/cookies-consent.service";
import DataSourceService from "./data-source.service";
import { DeviceType } from "../models/types/Device";
import { detectDeviceType } from "../utilities/device.utility";

class EventsTrackingService {
  private static instance: EventsTrackingService;
  private trackingService: TrackingService;
  private urlDetectionInitialized = false;

  private constructor(pobucaKey: string, private sessionId: string, private personId: string) {
    this.trackingService = TrackingService.getInstance(pobucaKey);
    this.initializeUrlDetection();
  }

  public static getInstance(pobucaKey: string, sessionId: string, personId: string): EventsTrackingService {
    if (!this.instance) {
      this.instance = new EventsTrackingService(pobucaKey, sessionId, personId);
    }
    return this.instance;
  }

  private initializeUrlDetection(): void {
    if (!this.urlDetectionInitialized) {
      const urlDetectionService = new UrlDetectionService();
      // urlDetectionService.onUrlChange(this.trackPageLoad.bind(this));
      urlDetectionService.onUrlChange(() => {
        DataSourceService.getInstance().trackedCategories.clear();
        this.trackPageLoad(window.location.href);
      });

      this.urlDetectionInitialized = true;
    }
  }

  public async trackPageLoad(pageUrl: string): Promise<void> {
    const SESSION_FLAG_KEY: string = 'isLandingPageTracked';
    const isFirstLoadInSession: boolean = !localStorage.getItem(SESSION_FLAG_KEY);
    const isLandingPage: boolean = isFirstLoadInSession;
    const queryParams: any = getAllUrlParams();
    const contactId: string | null = CookiesConsentService.isTrackingAllowed() ? loginService.getContactId() : null;

    if (isLandingPage) {
      localStorage.setItem(SESSION_FLAG_KEY, 'true');
    }

    const event: TrackingEvents = this.createTrackingEvent(
      'PageLoad',
      pageUrl,
      contactId,
      isLandingPage,
      queryParams
    );

    await this.sendTrackingData(event);
  }

  public async trackLoginEvent(contactId: string): Promise<void> {
    await this.sendTrackingData(this.createTrackingEvent('Login', window.location.href, contactId));
    await this.handlePopupsAfterEvent();
  }

  public async trackLogoutEvent(): Promise<void> {
    await this.sendTrackingData(this.createTrackingEvent('Logout', window.location.href));
    await this.handlePopupsAfterEvent();
  }

  public async trackAddToCart(productDetails: any, cartDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent('AddToCart', window.location.href);
    event.productDetails = productDetails;
    event.cartDetails = cartDetails;
    await this.sendTrackingData(event);
  }

  public async trackRemoveFromCart(productDetails: any, cartDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent('RemoveFromCart', window.location.href);
    event.productDetails = productDetails;
    event.cartDetails = cartDetails;
    await this.sendTrackingData(event);
  }

  // public async trackAbandonedCart(productDetails: any): Promise<void> {
  //   const event: TrackingEvents = this.createTrackingEvent('AbandonedCart', window.location.href);
  //   event.productDetails = productDetails;
  //   await this.sendTrackingData(event);
  // }

  public async trackViewItem(productDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent('ViewItem', window.location.href);
    event.productDetails = productDetails;
    await this.sendTrackingData(event);
  }

  public async trackViewItemList(categoryDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent("ViewItemList", window.location.href);
    event.categoryDetails = categoryDetails;
    await this.sendTrackingData(event);
  }

  public async trackPurchase(orderDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent('Purchase', window.location.href);
    event.orderDetails = orderDetails;
    await this.sendTrackingData(event);
  }

  public async trackAddToWishlist(productDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent("AddToWishlist", window.location.href);
    event.productDetails = productDetails;
    await this.sendTrackingData(event);
  }

  public async trackRemoveFromWishlist(productDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent("RemoveFromWishlist", window.location.href);
    event.productDetails = productDetails;
    await this.sendTrackingData(event);
  }

  public async trackSearch(searchDetails: any): Promise<void> {
    const event: TrackingEvents = this.createTrackingEvent("Search", window.location.href);
    event.searchDetails = searchDetails;
    await this.sendTrackingData(event);
  }

  public async subscribeToNewsletter(email: string): Promise<SubscriptionResponse> {
    const event: TrackingEvents = this.createTrackingEvent("Subscribe", window.location.href);
    event.email = email;
    event.language = navigator.language || document.documentElement.lang || 'en';
    const subscriptionResponse: SubscriptionResponse = await subscriptionService.getInstance().subscribeToNewsletterUser(
      email,
      event.language
    );
    if (subscriptionResponse.success) {
      await this.sendTrackingData(event);
    }
    return subscriptionResponse;
  }

  private async handlePopupsAfterEvent(): Promise<void> {
    const fetchPopupService: fetchPopupService = new FetchPopupService();
    const popupHandlerService: PopupHandlerService = new PopupHandlerService(this.trackingService);
    const deviceType: DeviceType = detectDeviceType();
    const popups: Popup[] = await fetchPopupService.fetchPopupData(deviceType);
    popupHandlerService.handlePopupTriggers(popups);
  }

  private createTrackingEvent(event: string, pageUrl: string, contactId: string | null = CookiesConsentService.isTrackingAllowed() ? loginService.getContactId() : null, landingPage?: boolean, queryParams?: any): TrackingEvents {
    return {
      event,
      personId: this.personId,
      sessionId: this.sessionId,
      pageUrl,
      date: getDate(),
      personType: visitorService.getPersonType(false),
      ...(contactId ? { contactId } : {}),
      ...(landingPage ? { landingPage } : {}),
      ...(queryParams ? { queryParams } : {}),
    };
  }

  private async sendTrackingData(eventData: TrackingEvents): Promise<void> {
    try {
      await this.trackingService.trackEvents(eventData);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking ${eventData.event} event, ${error}`);
    }
  }
}

export default EventsTrackingService;
