import { detectDeviceType } from "../../utilities/device.utility";
import { PopupEdge } from "../../models/enums/PopupEdges";
import { PopupPosition } from "../../models/enums/PopupPosition";
import { Popup } from "../../models/interfaces/Popup";
import { getPopupPositionStyles } from "../../utilities/popup-position.utility";
import managePopupService from "./manage-popup.service";
import trackImageService from "./track-image.service";

const STANDARD_TOP: string = '0';
const STANDARD_LEFT: string = '0';
const STANDARD_WIDTH: string = '100%';
const STANDARD_HEIGHT: string = '100%';
const STANDARD_BACKGROUND_COLOR: string = 'rgba(0, 0, 0, 0)';

class DisplayPopupService {
  private static instance: DisplayPopupService;
  private isPopupActive: boolean = false;

  public static getInstance(): DisplayPopupService {
    if (!this.instance) {
      this.instance = new DisplayPopupService();
    }
    return this.instance;
  }

  private setCommonStyles(element: HTMLElement, styles: Partial<CSSStyleDeclaration>): void {
    Object.assign(element.style, styles);
  }

  public isActive(): boolean {
    return this.isPopupActive;
  }

  public createPopupContainer(popup: Popup): HTMLDivElement {
    const isMobile: boolean = detectDeviceType() === "mobile";
    const isSurvey: boolean = popup.Action === 1 || popup.Action === 2;
    const popupContainer: HTMLDivElement = document.createElement('div');
    const POPUP = {
      DEFAULT_SIZE: 500,
      MOBILE_HEIGHT: 'auto',
      MOBILE_WIDTH: '100%',
      MOBILE_MAX_WIDTH: '350px',
      MOBILE_MAX_HEIGHT: '350px',
      CURVED_BORDER_RADIUS: '15px',
      SHARP_BORDER_RADIUS: '0px',
      Z_INDEX: '9999',
      BACKGROUND_COLOR: '#fff',
    }
    this.setCommonStyles(popupContainer, {
      position: 'fixed',
      zIndex: POPUP.Z_INDEX,
      width: isMobile ? POPUP.MOBILE_WIDTH : `${popup.Settings.width || POPUP.DEFAULT_SIZE}px`,
      height: isSurvey ? `${popup.Settings.height || POPUP.DEFAULT_SIZE}px` : (isMobile ? POPUP.MOBILE_HEIGHT : `${popup.Settings.height || POPUP.DEFAULT_SIZE}px`),
      maxWidth: isMobile ? POPUP.MOBILE_MAX_WIDTH : `${popup.Settings.width || POPUP.DEFAULT_SIZE}px`,
      maxHeight: isSurvey ? `${popup.Settings.height || POPUP.DEFAULT_SIZE}px` : (isMobile ? POPUP.MOBILE_MAX_HEIGHT : `${popup.Settings.height || POPUP.DEFAULT_SIZE}px`),
      overflow: 'hidden',
      borderRadius: popup.Settings.popupEdges === PopupEdge.Curved ? POPUP.CURVED_BORDER_RADIUS : POPUP.SHARP_BORDER_RADIUS,
      backgroundColor: popup.Settings.backgroundColor || POPUP.BACKGROUND_COLOR,
    });
    popupContainer.setAttribute('role', 'dialog');
    return popupContainer;
  }

  public createShadow(): HTMLDivElement {
    const shadow: HTMLDivElement = document.createElement('div');
    const SHADOW = {
      Z_INDEX: '9998',
      BACKGROUND_COLOR: 'rgba(0, 0, 0, 0.5)',
    };

    this.setCommonStyles(shadow, {
      position: 'fixed',
      top: STANDARD_TOP,
      left: STANDARD_LEFT,
      width: STANDARD_WIDTH,
      height: STANDARD_HEIGHT,
      backgroundColor: SHADOW.BACKGROUND_COLOR,
      zIndex: SHADOW.Z_INDEX
    });
    return shadow;
  }

  public createCloseButton(popupContainer: HTMLDivElement, shadow: HTMLDivElement, popup: Popup): HTMLButtonElement {
    const CLOSE_BUTTON = {
      MOBILE_TOP: "10px",
      DESKTOP_TOP: "12px",
      MOBILE_RIGHT: "13px",
      DESKTOP_RIGHT: "15px",
      BORDER_RADIUS: "50%",
      FONT_WEIGHT: "700",
      MOBILE_FONT_SIZE: "10px",
      DESKTOP_FONT_SIZE: "15px",
      MOBILE_SIZE: "20px",
      DESKTOP_SIZE: "30px",
      Z_INDEX: "1000",
      BACKGROUND_COLOR: "rgba(255, 255, 255, 0.6)",
      COLOR: "#000",
      COLOR_ON_HOVER: "rgba(255, 0, 0, 0.7)",
    };

    const isMobile: boolean = detectDeviceType() === "mobile";
    const closeButton: HTMLButtonElement = document.createElement('button');
    closeButton.textContent = 'X';
    this.setCommonStyles(closeButton, {
      position: 'absolute',
      top: isMobile ? CLOSE_BUTTON.MOBILE_TOP : CLOSE_BUTTON.DESKTOP_TOP,
      right: isMobile ? CLOSE_BUTTON.MOBILE_RIGHT : CLOSE_BUTTON.DESKTOP_RIGHT,
      backgroundColor: CLOSE_BUTTON.BACKGROUND_COLOR,
      color: popup.Settings.closeButtonColor || CLOSE_BUTTON.COLOR,
      border: 'none',
      cursor: 'pointer',
      borderRadius: CLOSE_BUTTON.BORDER_RADIUS,
      fontWeight: CLOSE_BUTTON.FONT_WEIGHT,
      fontSize: isMobile ? CLOSE_BUTTON.MOBILE_FONT_SIZE : CLOSE_BUTTON.DESKTOP_FONT_SIZE,
      width: isMobile ? CLOSE_BUTTON.MOBILE_SIZE : CLOSE_BUTTON.DESKTOP_SIZE,
      height: isMobile ? CLOSE_BUTTON.MOBILE_SIZE : CLOSE_BUTTON.DESKTOP_SIZE,
      transition: 'all 0.2s ease-in-out',
      zIndex: CLOSE_BUTTON.Z_INDEX,
    });

    closeButton.addEventListener('mouseenter', () => {
      closeButton.style.color = CLOSE_BUTTON.COLOR_ON_HOVER;
    });
    closeButton.addEventListener('mouseleave', () => {
      closeButton.style.color = popup.Settings.closeButtonColor || CLOSE_BUTTON.COLOR;
    });

    closeButton.addEventListener('click', () => {
      this.isPopupActive = false;
      popupContainer.remove();
      shadow.remove();
    });

    return closeButton;
  }

  public createIframe(popup: Popup): HTMLIFrameElement {
    const iframe: HTMLIFrameElement = document.createElement('iframe');
    iframe.src = `${popup.Url}?popup_id=${encodeURIComponent(popup.Id)}`;
    this.setCommonStyles(iframe, {
      border: 'none',
      width: STANDARD_WIDTH,
      height: STANDARD_HEIGHT
    });

    // Send postmessage to survey listener
    iframe.onload = () => {
      const iframeWindow: Window | null = iframe.contentWindow;
      if (iframeWindow && popup.Url) {
        iframeWindow.postMessage({ injectCompleteListener: true, popupId: popup.Id }, popup.Url);
      }
    };

    return iframe;
  }

  public positionPopup(popupContainer: HTMLDivElement, positionOnPage: PopupPosition): void {
    const isMobile: boolean = detectDeviceType() === "mobile";
    const styles = getPopupPositionStyles(positionOnPage, isMobile);
    this.setCommonStyles(popupContainer, styles);
  }

  private createImageOverlay(popup: Popup, personId: string, sessionId: string, personType: string, popupContainer: HTMLDivElement, shadow: HTMLDivElement): HTMLDivElement {
    const overlay: HTMLDivElement = document.createElement('div');
    this.setCommonStyles(overlay, {
      position: 'absolute',
      top: STANDARD_TOP,
      left: STANDARD_LEFT,
      width: STANDARD_WIDTH,
      height: STANDARD_HEIGHT,
      cursor: 'pointer',
      backgroundColor: STANDARD_BACKGROUND_COLOR
    });

    overlay.addEventListener('click', () => {
      managePopupService.markPopupAsConverted(popup.Id);
      trackImageService.handlePopupClick(popup.Id, popup.DestinationUrl!, personId, sessionId, personType, popupContainer, shadow, popup.Settings.openInNewWindow);
    });

    return overlay;
  }

  public displayPopup(popup: Popup, personId: string, sessionId: string, personType: string): void {
    if (this.isActive()) return;
    this.isPopupActive = true;

    const popupContainer: HTMLDivElement = this.createPopupContainer(popup);
    popupContainer.setAttribute('data-popup-id', popup.Id);

    const shadow: HTMLDivElement = this.createShadow();
    document.body.appendChild(shadow);

    if (popup.Action === 1 || popup.Action === 2) {
      const iframe: HTMLIFrameElement = this.createIframe(popup);
      popupContainer.appendChild(iframe);
    } else if (popup.Action === 3) {
      const img: HTMLImageElement = document.createElement('img');
      if (popup.ImageUrl) img.src = popup.ImageUrl;
      this.setCommonStyles(img, { width: '100%', height: '100%', objectFit: 'contain' });
      popupContainer.appendChild(img);

      if (!popup.DestinationUrl) {
        const overlay: HTMLDivElement = document.createElement('div');
        this.setCommonStyles(overlay, {
          position: 'absolute',
          top: STANDARD_TOP,
          left: STANDARD_LEFT,
          width: STANDARD_WIDTH,
          height: STANDARD_HEIGHT,
          cursor: 'pointer',
          backgroundColor: STANDARD_BACKGROUND_COLOR
        });

        overlay.addEventListener('click', () => {
          this.isPopupActive = false;
          popupContainer.remove();
          shadow.remove();
        });

        popupContainer.appendChild(overlay);
      } else {
        const overlay: HTMLDivElement = this.createImageOverlay(popup, personId, sessionId, personType, popupContainer, shadow);
        popupContainer.appendChild(overlay);
      }

    }

    const closeButton: HTMLButtonElement = this.createCloseButton(popupContainer, shadow, popup);
    const CLOSE_BTN_DELAY: number = 2000;

    setTimeout(() => {
      popupContainer.appendChild(closeButton);
    }, CLOSE_BTN_DELAY);

    this.positionPopup(popupContainer, popup.Settings.positionOnPage);

    document.body.appendChild(popupContainer);
  }
}

export default DisplayPopupService.getInstance();
