import errorService from "../error.service";
import CookieService from "./cookies.service";

class CookiesConsentService {
  private static instance: CookiesConsentService;

  private constructor() {
    this.setupConsentListeners();
  }

  public static getInstance(): CookiesConsentService {
    if (!this.instance) {
      this.instance = new CookiesConsentService();
    }
    return this.instance;
  }

  private setupConsentListeners(): void {
    (window as any).CookieConsentCallback_OnAccept = async () => this.setConsent(true);
    (window as any).CookieConsentCallback_OnDecline = async () => this.setConsent(false);
  }

  private async setConsent(isAccepted: boolean): Promise<void> {
    if (!this.isDebugMode() && !isAccepted) {
      await this.disableTrackingAndPopups();
    }
  }

  private async disableTrackingAndPopups(): Promise<void> {
    if (this.isDebugMode()) {
      errorService.errorHandler("Debug mode: Bypassing tracking and popup disablement.");
      return;
    }

    const cookieService: CookieService = CookieService.getInstance();
    cookieService.remove("pb_trackEvents");
    cookieService.remove("pb_displayPopups");
  }

  public isTrackingAllowed(): boolean {
    return this.isDebugMode() || CookieService.getInstance().hasUserConsent("statistics") || CookieService.getInstance().hasUserConsent("marketing");
  }

  public isPopupsAllowed(): boolean {
    return this.isDebugMode() || CookieService.getInstance().hasUserConsent("marketing");
  }

  public isDebugMode(): boolean {
    return localStorage.getItem("debugCookies") === "true";
  }
}

export default CookiesConsentService.getInstance();
