import { PopupPosition } from "../models/enums/PopupPosition";

export function getPopupPositionStyles(position: PopupPosition, isMobile: boolean): Partial<CSSStyleDeclaration> {
  const createPositionStyles = (top?: string, right?: string, bottom?: string, left?: string, transform?: string): Partial<CSSStyleDeclaration> => {
    return { top, right, bottom, left, transform };
  };

  if (isMobile) {
    return createPositionStyles("50%", undefined, undefined, "50%", "translate(-50%, -50%)");
  }

  const POSITION_STYLES: Record<PopupPosition, Partial<CSSStyleDeclaration>> = {
    [PopupPosition.TopRight]: createPositionStyles("10px", "10px"),
    [PopupPosition.TopLeft]: createPositionStyles("10px", undefined, undefined, "10px"),
    [PopupPosition.BottomRight]: createPositionStyles(undefined, "10px", "10px"),
    [PopupPosition.BottomLeft]: createPositionStyles(undefined, undefined, "10px", "10px"),
    [PopupPosition.Center]: createPositionStyles("50%", undefined, undefined, "50%", "translate(-50%, -50%)"),
    [PopupPosition.CenterLeft]: createPositionStyles("50%", undefined, undefined, "10px", "translateY(-50%)"),
    [PopupPosition.CenterRight]: createPositionStyles("50%", "10px", undefined, undefined, "translateY(-50%)"),
    [PopupPosition.TopCenter]: createPositionStyles("10px", undefined, undefined, "50%", "translateX(-50%)"),
    [PopupPosition.BottomCenter]: createPositionStyles(undefined, undefined, "10px", "50%", "translateX(-50%)"),
  };

  return POSITION_STYLES[position] || {};
};
