import errorService from "./error.service";
import configurationService from "./initialization/configuration.service";
import publicApiService from "./initialization/public-api.service";

const DATA_SOURCE: string = configurationService.getInstance().getDataSource();

class DataSourceService {
  private static instance: DataSourceService | null = null;
  public trackedCategories = new Set<string>();

  private constructor() {
    this.initializeDataSource();
  }

  public static getInstance(): DataSourceService {
    if (!this.instance) {
      this.instance = new DataSourceService();
    }
    return this.instance;
  }

  private initializeDataSource(): void {
    (window as any)[DATA_SOURCE] = (window as any)[DATA_SOURCE] || [];
    const dataSource: any = (window as any)[DATA_SOURCE];
    const originalPush: any = dataSource.push || Array.prototype.push;

    // Handle events that were pushed before the initialization of the dataLayer
    dataSource.forEach((event: any) => this.handlePreloadedEvent(event));

    dataSource.push = (...args: any[]) => {
      originalPush.apply(dataSource, args);
      this.handleDataSourceEvent(args);
    };
  }

  private handlePreloadedEvent(event: any): void {
    switch (event.event) {
      case "login":
        const contactInfo: string = event.email || event.contactInfo;
        publicApiService.onLogin(contactInfo);
        break;
      case "purchase":
        publicApiService.onPurchase(event.ecommerce);
        break;
      default:
        errorService.errorHandler(`Unhandled data layer event: ${event.event}`);
        break;
    }
  }

  private handleDataSourceEvent(events: any[]): void {
    events.forEach((event: any) => {
      switch (event.event) {
        case "login":
          const contactInfo: string = event.email || event.contactInfo;
          publicApiService.onLogin(contactInfo);
          break;
        case "logout":
          publicApiService.onLogout();
          break;
        case "add_to_cart":
          publicApiService.onAddToCart(event.ecommerce);
          break;
        case "remove_from_cart":
          publicApiService.onReduceProductFromCart(event.ecommerce);
          break;
        case "delete_from_cart":
          publicApiService.onDeleteEntireProductFromCart(event.ecommerce);
          break;
        case "view_item":
          publicApiService.onViewItem(event.ecommerce);
          break;
        case "view_item_list":
          const categoryId: string = event.ecommerce?.item_list_id;

          if (categoryId && !this.trackedCategories.has(categoryId)) {
            this.trackedCategories.add(categoryId);
            publicApiService.onViewItemList(event.ecommerce);
          }
          break;
        case "purchase":
          publicApiService.onPurchase(event.ecommerce);
          break;
        case "add_to_wishlist":
        case "add_to_list":
          publicApiService.onAddToWishlist(event.ecommerce);
          break;
        case "remove_from_wishlist":
        case "remove_from_list":
          publicApiService.onRemoveFromWishlist(event.ecommerce);
          break;
        case "search":
          publicApiService.onSearch(event.ecommerce);
          break;
        case "current_cart":
          publicApiService.onRetieveCurrentCart(event.ecommerce);
          break;
        default:
          errorService.errorHandler(`Unhandled data layer event: ${event.event}`);
      }
    });
  }
}

export default DataSourceService;
