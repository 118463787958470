const PREFIX = `pb-`;

class LocalStorageService {
  private static instance: LocalStorageService;

  constructor() {
    if (LocalStorageService.instance) return LocalStorageService.instance;
    LocalStorageService.instance = this;
  }

  public get(key: string): any {
    const item: any = window.localStorage.getItem(`${PREFIX}${key}`);
    return item ? JSON.parse(item) : '';
  }

  public save(key: string, value: any): void {
    window.localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
  }

  public delete(key: string): void {
    window.localStorage.removeItem(`${PREFIX}${key}`);
  }

  public clear(): void {
    window.localStorage.clear();
  }
}

export default new LocalStorageService();
