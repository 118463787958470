import errorService from "./error.service";
import publicApiService from "./initialization/public-api.service";
import { SubscriptionResponse } from "./events/subscriptionToNewsletter.service";

class PbWebsitesService {
  private static instance: PbWebsitesService | null = null;

  public static getInstance(): PbWebsitesService {
    if (!this.instance) {
      this.instance = new PbWebsitesService();
    }
    return this.instance;
  }

  public async subscribeToNewsletter(email: string): Promise<SubscriptionResponse> {
    try {
      const response: SubscriptionResponse = await publicApiService.onSubscribeToNewsletter(email);
      return response;
    } catch (error) {
      errorService.errorHandler(`Error during subscription: ${error}`);
      return {
        success: false,
        message: "An unexpected error occurred.",
      };
    }
  }
}

export default PbWebsitesService;
