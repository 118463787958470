import { Popup } from "../../models/interfaces/Popup";
import { TrackingPopups } from "../../models/interfaces/TrackingPopups";
import { CustomEvent } from "../../models/types/Event";
import CookieService from "../cookies/cookies.service";
import localStorageService from "../local-storage.service";
import loginService from "../events/login.service";
import { getDate } from "../../utilities/date.utility";
import CookiesConsentService from "../cookies/cookies-consent.service";

class PopupStatisticsService {
  private cookieService: CookieService = CookieService.getInstance();

  public updatePopupStatistics(popup: Popup, trigger: CustomEvent, sessionId: string): TrackingPopups {
    let popupStatistics: TrackingPopups[] = this.getPopupStatistics();
    const existingPopupIndex: number = popupStatistics.findIndex(stat => stat.popupId === popup.Id);
    const currentTimestamp: string = getDate();
    const pageUrl: string = window.location.href;

    const personId: string = localStorageService.get("websites")?.personId || 'unknown';
    const personType: string = localStorageService.get("websites")?.personType || 'unknown';
    const contactId: string | null = CookiesConsentService.isTrackingAllowed() || this.cookieService.hasUserConsent('marketing') ? loginService.getContactId() : null;

    if (existingPopupIndex !== -1) {
      popupStatistics[existingPopupIndex].impressions += 1;
      popupStatistics[existingPopupIndex].triggerType = trigger.type;
      popupStatistics[existingPopupIndex].date = currentTimestamp;
      popupStatistics[existingPopupIndex].pageUrl = pageUrl;

    } else {
      const newPopupStat: TrackingPopups = {
        popupId: popup.Id,
        pageUrl: pageUrl,
        personId: personId,
        personType: personType,
        ...(contactId ? { contactId } : {}),
        date: currentTimestamp,
        sessionId: sessionId,
        impressions: 1,
        triggerType: trigger.type,
        event: "PopupImpression",
      };

      popupStatistics.push(newPopupStat);
    }
    localStorageService.save("popup-statistics", popupStatistics);

    return existingPopupIndex !== -1 ? popupStatistics[existingPopupIndex] : popupStatistics[popupStatistics.length - 1];
  }

  public getPopupStatistics(): TrackingPopups[] {
    const storedStatistics: TrackingPopups[] | null = localStorageService.get("popup-statistics");
    return storedStatistics && Array.isArray(storedStatistics)
      ? storedStatistics
      : [];
  }
}

export default new PopupStatisticsService();
