import errorService from "../error.service";
import EventsTrackingService from "../events-tracking.service";
import configurationService from "../initialization/configuration.service";
import sessionService from "../initialization/session.service";
import visitorService from "../initialization/visitor.service";

class WishlistService {
  private static instance: WishlistService;
  private eventTrackingService: EventsTrackingService | null = null;
  private wishlistState: any[] = [];

  private constructor() {
    const savedWishlist: string | null = localStorage.getItem("pb-wishlist");
    this.wishlistState = savedWishlist ? JSON.parse(savedWishlist) : [];
  }

  public static getInstance(): WishlistService {
    if (!this.instance) {
      this.instance = new WishlistService();
    }
    return this.instance;
  }

  private async getEventTrackingService(): Promise<EventsTrackingService> {
    if (!this.eventTrackingService) {
      const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
      if (!pobucaKey) throw new Error("Pobuca key not found.");

      const sessionId: string = sessionService.getSessionInfo()?.sessionId || sessionService.createNewSession().sessionId;
      const personId: string = visitorService.getPersonId();

      this.eventTrackingService = EventsTrackingService.getInstance(pobucaKey, sessionId, personId);
    }
    return this.eventTrackingService;
  }

  private saveToLocalStorage(): void {
    localStorage.setItem("pb-wishlist", JSON.stringify(this.wishlistState));
  }

  private normalizeItemData(item: any): any {
    return {
      item_id: item.item_id,
      ...item,
    };
  }

  public async handleAddToWishlist(data: any): Promise<void> {
    try {
      const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
      const itemsArray: any = Array.isArray(data.items) ? data.items : [data.items];

      itemsArray.forEach((productItem: any) => {
        const normalizedItem: any = this.normalizeItemData(productItem);
        const existingItemIndex: number = this.wishlistState.findIndex(
          (wishlistItem) => wishlistItem.item_id === normalizedItem.item_id
        );

        if (existingItemIndex === -1) {
          this.wishlistState.push(normalizedItem);
        } else {
          errorService.errorHandler(`Item ID ${normalizedItem.item_id} is already in wishlist`);
        }
      });

      this.saveToLocalStorage();
      await eventTrackingService.trackAddToWishlist(data);
    } catch (error: unknown) {
      errorService.errorHandler(`Error adding to wishlist: ${error}`);
    }
  }

  public async handleRemoveFromWishlist(data: any): Promise<void> {
    try {
      const eventTrackingService: EventsTrackingService = await this.getEventTrackingService();
      const itemsArray: any = Array.isArray(data.items) ? data.items : [data.items];

      itemsArray.forEach((productItem: any) => {
        const normalizedItem: any = this.normalizeItemData(productItem);
        const existingItemIndex: number = this.wishlistState.findIndex(
          (wishlistItem) => wishlistItem.item_id === normalizedItem.item_id
        );

        if (existingItemIndex !== -1) {
          this.wishlistState.splice(existingItemIndex, 1);
        } else {
          errorService.errorHandler(`Item ID ${normalizedItem.item_id} not found in wishlist`);
        }
      });

      this.saveToLocalStorage();
      await eventTrackingService.trackRemoveFromWishlist(data);
    } catch (error: unknown) {
      errorService.errorHandler(`Error removing from wishlist: ${error}`);
    }
  }
}

export default WishlistService;
