import { getTrackingApiUrl } from "../../utilities/tracking-api.utility";
import configurationService from "../initialization/configuration.service";

export interface SubscriptionResponse {
  success: boolean;
  message: string;
}

class SubscriptionToNewsletterService {
  private static instance: SubscriptionToNewsletterService | null = null;

  public static getInstance(): SubscriptionToNewsletterService {
    if (!this.instance) {
      this.instance = new SubscriptionToNewsletterService();
    }
    return this.instance;
  }

  public async subscribeToNewsletterUser(
    email: string,
    language: string
  ): Promise<SubscriptionResponse> {
    const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
    if (!pobucaKey) throw new Error('Pobuca key not found.');

    const endpoint: string = `${getTrackingApiUrl()}/SubscribeNewsletter`;
    try {
      const response: Response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-functions-key': pobucaKey
        },
        body: JSON.stringify({ "email": email, "language": language }),
      });

      const textResponse: string = await response.text();
      return this.mapSubscriptionResponse(parseInt(textResponse, 10));
    } catch (error: any) {
      return {
        success: false,
        message: 'An unexpected error occurred during subscription.',
      };
    }
  }

  private mapSubscriptionResponse(statusCode: number): SubscriptionResponse {
    const responseMapping: Record<number, SubscriptionResponse> = {
      201: { success: true, message: "created" },
      200: { success: true, message: "updated" },
      304: { success: false, message: "not modified" },
      400: { success: false, message: "bad request" },
      500: { success: false, message: "internal server error" },
    };
    return responseMapping[statusCode] || { success: false, message: "Unexpected response from server." };
  }
}

export default SubscriptionToNewsletterService;
