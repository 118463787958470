class ConfigurationService {
  private static instance: ConfigurationService;

  public static getInstance(): ConfigurationService {
    if (!this.instance) {
      this.instance = new ConfigurationService();
    }
    return this.instance;
  }

  public getPobucaKey(): string | null {
    const pobucaScript: Element | null = document.querySelector('script[pobuca-websites-key]');
    return pobucaScript ? pobucaScript.getAttribute('pobuca-websites-key') : null;
  }

  public getDataSource(): string {
    const DATA_SOURCE: string = "dataLayer";
    return DATA_SOURCE;
  }

  public getWebPushPublicKey(): string | null {
    const webPushScript: Element | null = document.querySelector('script[web-push-public-key]');
    return webPushScript ? webPushScript.getAttribute('web-push-public-key') : null;
  }
}

export default ConfigurationService;
