import { SessionInfo } from "../../models/interfaces/SessionInfo";
import { WebsitesState } from "../../models/interfaces/WebsitesState";
import { getDate } from "../../utilities/date.utility";
import { generateGuid } from "../../utilities/guid.utility";
import localStorageService from "../local-storage.service";
import UrlDetectionService from "../url-detection.service";

class SessionService {
  private static instance: SessionService;

  constructor() {
    if (SessionService.instance) return SessionService.instance;
    SessionService.instance = this;
    this.initializeUrlDetection();
  }

  public sessionTimeout: number = 60 * 60 * 1000; // 60 minutes

  private initializeUrlDetection(): void {
    const urlDetectionService: UrlDetectionService = new UrlDetectionService();
    urlDetectionService.onUrlChange((newURL: string) => {
      const sessionInfo: SessionInfo | null = this.getSessionInfo();
      if (sessionInfo) {
        this.updateSessionInfo(sessionInfo, newURL);
      }
    });
  }

  private getWebsitesState(): WebsitesState {
    return localStorageService.get("websites") || {};
  }

  private saveWebsitesState(state: WebsitesState): void {
    return localStorageService.save("websites", state);
  }

  public getSessionInfo(): SessionInfo | null {
    const websitesState: WebsitesState = this.getWebsitesState();
    const sessionInfo: SessionInfo | null = websitesState.sessionInfo || null;

    if (sessionInfo && !this.isSessionExpired(sessionInfo.lastEventTimeStamp)) {
      return sessionInfo;
    }

    return this.createNewSession();
  }

  public createNewSession(): SessionInfo {
    const sessionId: string = generateGuid();
    const sessionInfo: SessionInfo = {
      sessionId: sessionId,
      sessionPages: 0,
      lastEventTimeStamp: getDate(),
      visitedUrls: [],
    };

    const websitesState: WebsitesState = this.getWebsitesState();
    localStorage.removeItem('isLandingPageTracked');

    if (this.isSessionExpired(websitesState.sessionInfo?.lastEventTimeStamp || "")) {
      if (websitesState.personType === "first-time-visitor") {
        websitesState.personType = "returning-visitor";
      }
    }
    websitesState.sessionInfo = sessionInfo;
    this.saveWebsitesState(websitesState);

    return sessionInfo;
  }

  public updateSessionInfo(sessionInfo: SessionInfo, url: string = window.location.href): void {
    sessionInfo.sessionPages += 1;
    sessionInfo.lastEventTimeStamp = getDate();
    sessionInfo.visitedUrls.push(url);

    const websitesState: WebsitesState = this.getWebsitesState();
    websitesState.sessionInfo = sessionInfo;
    this.saveWebsitesState(websitesState);
  }

  public updateLastEventTimestamp(): void {
    const websitesState: WebsitesState = this.getWebsitesState();
    const sessionInfo: SessionInfo | null = websitesState.sessionInfo || null;

    if (sessionInfo) {
      sessionInfo.lastEventTimeStamp = getDate();
      websitesState.sessionInfo = sessionInfo;
      this.saveWebsitesState(websitesState);
    }
  }

  public isSessionExpired(lastEventTimeStamp: string, timeout = this.sessionTimeout): boolean {
    const lastEventDate: number = new Date(lastEventTimeStamp).getTime();
    const currentTime: number = new Date(getDate()).getTime();
    return currentTime - lastEventDate > timeout;
  }
}

export default new SessionService();
