import { Popup } from "../../models/interfaces/Popup";
import { SessionInfo } from "../../models/interfaces/SessionInfo";
import { TrackingPopups } from "../../models/interfaces/TrackingPopups";
import { DeviceType } from "../../models/types/Device";
import { TargetGroup } from "../../models/types/TargetGroup";
import errorService from "../error.service";
import localStorageService from "../local-storage.service";
import priorityStateService from "./priority-state.service";
import { UrlRuleSet } from "../../models/interfaces/Url";
import { UrlRule } from "../../models/enums/UrlRule";
import { getDate } from "../../utilities/date.utility";
import { detectDeviceType } from "../../utilities/device.utility";

class ManagePopupService {
  private static instance: ManagePopupService;

  constructor() {
    if (ManagePopupService.instance) return ManagePopupService.instance;
    ManagePopupService.instance = this;
  }

  private getConvertedPopupState(): string[] {
    const convertedPopupState: string[] = localStorageService.get("converted-popups");
    if (Array.isArray(convertedPopupState)) {
      return convertedPopupState;
    } else {
      localStorageService.save("converted-popups", []);
      return [];
    }
  }

  public markPopupAsConverted(popupId: string): void {
    const convertedPopups: string[] = this.getConvertedPopupState();
    if (!convertedPopups.includes(popupId)) {
      convertedPopups.push(popupId);
      localStorageService.save("converted-popups", convertedPopups);
    }
  }

  private getCurrentPopupState(): TrackingPopups[] {
    const currentPopupState: TrackingPopups = localStorageService.get("popup-statistics");
    if (Array.isArray(currentPopupState)) {
      return currentPopupState;
    } else {
      localStorageService.save("popup-statistics", []);
      return [];
    }
  }

  public canShowPopup(popup: Popup): boolean {

    if (this.isPopupConverted(popup)) {
      errorService.errorHandler(`Popup ${popup.Id} has been converted.`);
      return false;
    }

    if (this.isHigherPriorityPopupShown(popup)) {
      errorService.errorHandler("Other priority popup is first. Skipping this popup.");
      return false;
    }

    if (this.hasReachedMaxImpressions(popup)) {
      errorService.errorHandler(`Maximum impressions reached for popup: ${popup.Id}`);
      return false;
    }

    if (!this.isVisitorTypeSupported(popup)) {
      errorService.errorHandler(`Visitor type is not supported for popup: ${popup.Id}`);
      return false;
    }

    if (!this.isDeviceSupported(popup)) {
      errorService.errorHandler(`Device type is not supported for popup: ${popup.Id}`);
      return false;
    }

    if (!this.hasReappearTimeElapsed(popup)) {
      errorService.errorHandler(`Reappear time has not elapsed for popup: ${popup.Id}`);
      return false;
    }

    if (!this.hasVisitedRequiredPages(popup)) {
      errorService.errorHandler(`User hasn't visited enough pages for popup: ${popup.Id}`);
      return false;
    }

    if (!this.isUrlSupported(popup, window.location.href)) {
      errorService.errorHandler(`URL is not supported for popup: ${popup.Id}`);
      return false;
    }

    return true;
  }

  private isPopupConverted(popup: Popup): boolean {
    const convertedPopups: string[] = this.getConvertedPopupState();
    return convertedPopups.includes(popup.Id);
  }

  private isDeviceSupported(popup: Popup): boolean {
    const deviceType: DeviceType = detectDeviceType();
    const isDeviceSupported: boolean = popup.Settings.supportedDevices.includes(deviceType);

    return isDeviceSupported;
  }

  private isUrlSupported(popup: Popup, newUrl: string): boolean {
    const urlRuleSets: UrlRuleSet[][] = popup.Settings.urlRuleSet;
    if (!urlRuleSets || !urlRuleSets.length) return false;

    for (const ruleGroup of urlRuleSets) {
      let isGroupMatched: boolean = false;

      for (const condition of ruleGroup) {
        const { rule, value }: UrlRuleSet = condition;

        switch (rule) {
          case UrlRule.Contains:
            if (newUrl.includes(value)) isGroupMatched = true;
            break;
          case UrlRule.IsExactly:
            if (newUrl === value) isGroupMatched = true;
            break;
          case UrlRule.StartsWith:
            if (newUrl.startsWith(value)) isGroupMatched = true;
            break;
          case UrlRule.EndsWith:
            if (newUrl.endsWith(value)) isGroupMatched = true;
            break;
          case UrlRule.NotContains:
            if (!newUrl.includes(value)) isGroupMatched = true;
            break;
          case UrlRule.NotIsExactly:
            if (newUrl !== value) isGroupMatched = true;
            break;
          case UrlRule.NotStartsWith:
            if (!newUrl.startsWith(value)) isGroupMatched = true;
            break;
          case UrlRule.NotEndsWith:
            if (!newUrl.endsWith(value)) isGroupMatched = true;
            break;
          default:
            errorService.errorHandler(`Unknown select condition: ${rule}`);
        }

        if (isGroupMatched) break;
      }
      if (!isGroupMatched) return false;
    }
    return true;
  }

  private hasReachedMaxImpressions(popup: Popup): boolean {
    const stats: TrackingPopups[] = this.getCurrentPopupState();
    const popupStats: TrackingPopups | undefined = stats.find(stat => stat.popupId === popup.Id);
    if (!popupStats) return false;
    return popupStats.impressions >= (popup.Settings.maxImpressions ?? Number.MAX_SAFE_INTEGER);
  }

  private isHigherPriorityPopupShown(currentPopup: Popup): boolean {
    const currentQueue: string[] = priorityStateService.getCurrentPriorityState();
    return currentQueue.some((priorityEntry) => {
      const parts: string[] = priorityEntry.split('-');
      if (parts.length !== 3 || parts[1] !== 'priority') return false;

      const type: string = parts[0];
      const priority: number = parseInt(parts[2], 10);
      const hasMatchingTrigger: boolean = currentPopup.Settings.triggersWith.some(trigger => trigger.type === type);
      if (!hasMatchingTrigger) return false;
      return priority < (currentPopup.Priority ?? Infinity);
    });
  }

  private isVisitorTypeSupported(popup: Popup): boolean {
    const visitorType: TargetGroup = localStorageService.get("websites").personType;
    return popup.Settings.targetGroups.includes(visitorType);
  }

  private hasReappearTimeElapsed(popup: Popup): boolean {
    const popupStatistics = this.getCurrentPopupState();
    const popupStat: TrackingPopups | undefined = popupStatistics.find((stat: { popupId: string; }) => stat.popupId === popup.Id);

    if (!popupStat || !popupStat.date) return true;

    const lastShownDate: Date = new Date(popupStat.date);
    const reappearTimeInMs: number = (popup.Settings.reappearTime ?? 0) * 60 * 1000;
    const timeSinceLastShown: number = new Date(getDate()).getTime() - lastShownDate.getTime();
    return timeSinceLastShown >= reappearTimeInMs;
  }

  private hasVisitedRequiredPages(popup: Popup): boolean {
    const sessionInfo: SessionInfo = localStorageService.get("websites").sessionInfo;
    const visitedPages: number = sessionInfo?.sessionPages || 0;
    return visitedPages >= (popup.Settings.afterPages ?? 0);
  }
}

export default new ManagePopupService();
