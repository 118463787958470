import { TrackingEvents } from "../../models/interfaces/TrackingEvents";
import { getDate } from "../../utilities/date.utility";
import errorService from "../error.service";
import loginService from "../events/login.service";
import TrackingService from "../tracking.service";
import managePopupService from "./manage-popup.service";

class ConversionEventService {
  private trackingService: TrackingService;
  private personId!: string;
  private sessionId!: string;
  private personType!: string;
  private boundEventListener: (event: MessageEvent) => Promise<void>;

  constructor(pobucaKey: string) {
    this.trackingService = TrackingService.getInstance(pobucaKey);
    this.boundEventListener = this.eventListener.bind(this);
  }

  public listenForEvents(personId: string, sessionId: string, personType: string): void {
    this.personId = personId;
    this.sessionId = sessionId;
    this.personType = personType;
    window.addEventListener('message', this.boundEventListener);
  }

  private async eventListener(event: MessageEvent): Promise<void> {
    const popupId: string = event.data.popupId ? decodeURIComponent(event.data.popupId) : '';

    if (event.origin === 'https://survey.pobuca.com' && event.data.action === 'surveyComplete') {
      await this.trackCompleteEvent(window.location.href, this.personId, this.sessionId, this.personType, popupId);
      managePopupService.markPopupAsConverted(popupId);
    } else if (event.origin === 'https://assets-eur.mkt.dynamics.com' && event.data.action === 'FormComplete') {
      managePopupService.markPopupAsConverted(popupId);
      await this.trackCompleteEvent(window.location.href, this.personId, this.sessionId, this.personType, popupId);
    }
  }

  public async trackCompleteEvent(pageUrl: string, personId: string, sessionId: string, personType: string, popupId: string): Promise<void> {
    const contactId: string | null = loginService.getContactId();
    if (contactId) {
      personType = "logged-in";
    }
    const eventData: TrackingEvents = {
      event: "PopupConversion",
      popupId: popupId,
      personId: personId,
      sessionId: sessionId,
      pageUrl: pageUrl,
      date: getDate(),
      personType: personType,
      ...(contactId ? { contactId } : {})
    };

    try {
      await this.trackingService.trackEvents(eventData);
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking completion, ${error}`);
    }
  }
}

export default ConversionEventService;
