export enum UrlRule {
  Contains = 'contains',
  IsExactly = 'is_exactly',
  StartsWith = 'startswith',
  EndsWith = 'endswith',
  NotContains = 'not_contains',
  NotIsExactly = 'not_is_exactly',
  NotStartsWith = 'not_startswith',
  NotEndsWith = 'not_endswith'
}
