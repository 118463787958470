import errorService from "../error.service";
import configurationService from "../initialization/configuration.service";
import ConversionEventService from "./conversion-event.service";

class PopupTrackingService {
  private conversionEventService: ConversionEventService;

  constructor() {
    const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
    if (!pobucaKey) throw new Error('Pobuca key not found.');
    this.conversionEventService = new ConversionEventService(pobucaKey);
  }

  public async handlePopupClick(
    popupId: string,
    targetUrl: string,
    personId: string,
    sessionId: string,
    personType: string,
    popupContainer: HTMLElement,
    shadow: HTMLElement,
    targetBlank: boolean
  ): Promise<void> {
    window.open(targetUrl, targetBlank ? '_blank' : '_self');

    try {
      await this.conversionEventService.trackCompleteEvent(
        window.location.href,
        personId,
        sessionId,
        personType,
        popupId
      );
    } catch (error: unknown) {
      errorService.errorHandler(`Error tracking popup conversion:, ${error}`);
    }
    popupContainer.remove();
    shadow.remove();
  }
}

export default new PopupTrackingService();
