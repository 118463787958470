class ErrorService {
  private static instance: ErrorService;

  constructor() {
    if (ErrorService.instance) return ErrorService.instance;
    ErrorService.instance = this;
  }

  // Add in the website console `localStorage.setItem('debug', 'true')` to see the messages.
  public errorHandler(message: unknown): void {
    const isDebugModeOn: boolean = localStorage.getItem('debug') === 'true';
    if (isDebugModeOn) console.warn(message);
  }
}

export default new ErrorService();