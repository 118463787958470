import { Popup } from "../../models/interfaces/Popup";
import localStorageService from "../local-storage.service";
import TrackingService from "../tracking.service";
import TriggerPopupService from "./trigger-popup.service";
import errorService from "../error.service";
import { WebsitesState } from "../../models/interfaces/WebsitesState";
import { CustomEvent } from "../../models/types/Event";

class PopupHandlerService {
  private triggerPopupService: TriggerPopupService;

  constructor(trackingService: TrackingService) {
    this.triggerPopupService = new TriggerPopupService(trackingService);
  }

  public handlePopupTriggers(popups: Popup[]) {
    document.querySelectorAll('[data-popup-id]').forEach(popup => popup.remove()); //Clears old popups when new are fetched

    const sortedPopups: Popup[] = [...popups].sort((popupA, popupB) => {
      const priorityA: number = popupA.Priority ?? Infinity;
      const priorityB: number = popupB.Priority ?? Infinity;
      return priorityA - priorityB;
    });

    sortedPopups.forEach((popup) => {
      try {
        const websitesState: WebsitesState = localStorageService.get("websites");
        const { sessionInfo, personId, personType } = websitesState;
        const triggers: CustomEvent[] = popup.Settings.triggersWith;

        if (triggers && Array.isArray(triggers) && triggers.length > 0) {
          triggers.forEach((trigger) => {
            switch (trigger.type) {
              case "LoadEvent":
                this.triggerPopupService.handleLoadEvent(trigger, popup, sessionInfo.sessionId, personId, personType);
                break;
              case "ClickEvent":
                this.triggerPopupService.handleClickEvent(trigger, popup, sessionInfo.sessionId, personId, personType);
                break;
              case "ScrollEvent":
                this.triggerPopupService.handleScrollEvent(trigger, popup, sessionInfo.sessionId, personId, personType);
                break;
              case "ExitEvent":
                this.triggerPopupService.handleExitEvent(trigger, popup, sessionInfo.sessionId, personId, personType);
                break;
              default:
                errorService.errorHandler(`Unknown trigger type: ${trigger}`);
            }
          });
        } else {
          errorService.errorHandler(`No valid triggers found for popup: ${popup.Id}`);
        }
      } catch (error: unknown) {
        errorService.errorHandler(`Error handling popup ${popup.Id}: ${error || JSON.stringify(error)}`);
      }
    });
  }
}

export default PopupHandlerService;
