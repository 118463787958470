const generateSegment = (length: number): string => {
  return [...Array(length)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
};

export const generateGuid = (): string => {
  return (
    generateSegment(8) +
    '-' +
    generateSegment(4) +
    '-' +
    '4' + generateSegment(3) +
    '-' +
    ((Math.floor(Math.random() * 4) + 8).toString(16)) +
    generateSegment(3) +
    '-' +
    generateSegment(12)
  );
};