import { WebsitesState } from "../../models/interfaces/WebsitesState";
import { getTrackingApiUrl } from "../../utilities/tracking-api.utility";
import errorService from "../error.service";
import EventsTrackingService from "../events-tracking.service";
import configurationService from "../initialization/configuration.service";
import sessionService from "../initialization/session.service";
import visitorService from "../initialization/visitor.service";
import localStorageService from "../local-storage.service";
import PushNotificationService from "../push-notification.service";
import CookiesConsentService from "../cookies/cookies-consent.service";

class LoginService {
  private eventsTrackingService: EventsTrackingService | null = null;

  public async initialize(): Promise<void> {
    if (!this.eventsTrackingService) {
      const { pobucaKey, sessionId, personId } = await this.initEventTracking();
      this.eventsTrackingService = EventsTrackingService.getInstance(pobucaKey, sessionId, personId);
    }
  }

  private async initEventTracking(): Promise<{ pobucaKey: string; sessionId: string; personId: string }> {
    const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
    if (!pobucaKey) throw new Error('Pobuca key not found.');

    const sessionId: string = sessionService.getSessionInfo()?.sessionId || '';
    const personId: string = visitorService.getPersonId();
    return { pobucaKey, sessionId, personId };
  }

  public async handleUserLogin(contactInfo: string): Promise<void> {
    if (!contactInfo) {
      throw new Error("Contact information must be provided.");
    }

    const { email, phone } = this.identifyContactType(contactInfo);

    if (!email && !phone) throw new Error('Email or phone number must be provided.');

    try {
      const contactId: string = await this.fetchContactId(email, phone) || "";
      await this.setLoggedInPerson(contactId);
    } catch (error: unknown) {
      errorService.errorHandler(`Error handling login event, ${error}`);
    }
  }

  private identifyContactType(contactInfo: string): { email: string | null; phone: string | null } {
    const isEmail: boolean = this.isValidEmail(contactInfo);
    const isPhone: boolean = this.isValidPhone(contactInfo);

    return {
      email: isEmail ? contactInfo : null,
      phone: isPhone ? contactInfo : null,
    };
  }

  private isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  private isValidPhone(phone: string): boolean {
    const phoneRegex: RegExp = /^(\+?[1-9]\d{0,14})([ -]?(\d+))*([ ]?(ext|x)\d+)?$/i;
    return phoneRegex.test(phone);
  }

  public async fetchContactId(email: string | null, phone: string | null): Promise<string | null> {
    const requestBody: any = {
      LogicalOperator: "Or",
    };

    if (email) {
      requestBody.Email = email;
    }
    if (phone) {
      requestBody.PhoneNumber = phone;
    }
    const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
    if (!pobucaKey) throw new Error('Pobuca key not found.');

    const apiUrl: string = `${getTrackingApiUrl()}/ContactInfo`;
    try {
      const response: Response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-functions-key': pobucaKey
        },
        body: JSON.stringify(requestBody),
      });
      const data: any = await response.json();
      return data.contactId || null;
    } catch (error: unknown) {
      errorService.errorHandler(`Error fetching contact ID: , ${error}`);
      return null;
    }
  }

  private async setLoggedInPerson(contactId: string): Promise<void> {
    const websitesState: WebsitesState = localStorageService.get("websites") || {};
    websitesState.personType = "logged-in";
    websitesState.contactId = CookiesConsentService.isTrackingAllowed() && contactId ? contactId : null;
    localStorageService.save("websites", websitesState);

    const trackingContactId: string = websitesState.contactId || "";
    await this.eventsTrackingService?.trackLoginEvent(trackingContactId);
  }

  public async setLoggedOutPerson(): Promise<void> {
    const websitesState: WebsitesState = localStorageService.get("websites") || {};
    websitesState.personType = "returning-visitor";
    websitesState.contactId = null;
    localStorageService.save("websites", websitesState);
    await PushNotificationService.anonymizeVisitor();
    await this.eventsTrackingService?.trackLogoutEvent();
  }

  public getContactId(): string | null {
    return localStorageService.get("websites")?.contactId || null;
  }
}

export default new LoginService();
