export enum PopupPosition {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  Center = 'center',
  CenterLeft = 'center-left',
  CenterRight = 'center-right',
}
