import { WebsitesState } from "../../models/interfaces/WebsitesState";
import { TargetGroup } from "../../models/types/TargetGroup";
import { generateGuid } from "../../utilities/guid.utility";
import localStorageService from "../local-storage.service";

class VisitorService {
  private static instance: VisitorService;

  constructor() {
    if (VisitorService.instance) return VisitorService.instance;
    VisitorService.instance = this;
  }

  public getPersonId(): string {
    const websitesState: WebsitesState = localStorageService.get("websites") || {};
    let personId: string = websitesState.personId;

    if (!personId) {
      personId = generateGuid();
      websitesState.personId = personId;
      localStorageService.save("websites", websitesState);
    }
    return personId;
  }

  public getPersonType(isSessionExpired: boolean): TargetGroup {
    const websitesState: WebsitesState = localStorageService.get("websites") || {};
    let personType: TargetGroup = websitesState.personType as TargetGroup;

    if (!personType) {
      personType = "first-time-visitor";
    } else if (isSessionExpired && personType === "first-time-visitor") {
      personType = "returning-visitor";
    }

    websitesState.personType = personType;
    localStorageService.save("websites", websitesState);

    return personType;
  }
}

export default new VisitorService();
