class UrlDetectionService {
    private currentURL: string | undefined;
    private onChange: ((newURL: string) => void) | undefined;

    public onUrlChange(callback: (newURL: string) => void): void {
        this.currentURL = window.location.href;
        this.onChange = callback;
        window.addEventListener('popstate', this.handleURLChange.bind(this));
        this.overrideHistoryMethods();
        this.handleURLChange();
    }

    private overrideHistoryMethods(): void {
        const originalPushState: any = history.pushState;
        const originalReplaceState: any = history.replaceState;

        history.pushState = (...args) => {
            const result: any = originalPushState.apply(history, args);
            this.handleURLChange();
            return result;
        };

        history.replaceState = (...args) => {
            const result: any = originalReplaceState.apply(history, args);
            this.handleURLChange();
            return result;
        };
    }

    private handleURLChange(): void {
        const newURL: string = window.location.href;
        if (this.onChange && newURL !== this.currentURL) {
            this.onChange(newURL);
            this.currentURL = newURL;
        }
    }
}
export default UrlDetectionService;