import { getTrackingApiUrl } from "../utilities/tracking-api.utility";
import CookieService from "./cookies/cookies.service";
import errorService from "./error.service";
import configurationService from "./initialization/configuration.service";
import localStorageService from "./local-storage.service";

class PushNotificationService {
  private static apiUrl: string = `${getTrackingApiUrl()}/WebPush`;
  private static publicKey: string | null = configurationService.getInstance().getWebPushPublicKey();
  private static swPath: string = "/pb-sw.js";
  private static pobucaKey: string | null = configurationService.getInstance().getPobucaKey();

  private static getQueryString(): string {
    const serverUrl: string = this.apiUrl;
    const pobucaKey: string = this.pobucaKey || "";

    return `?serverUrl=${serverUrl}&pobucaKey=${pobucaKey}`;
  }

  public static async initialize(): Promise<void> {
    try {
      const cookieService: CookieService = CookieService.getInstance();
      if (cookieService.get("pb_pushNotifications") === "enabled") {
        await this.registerServiceWorker();
      }

      const isRequestPermissionEnabled: string | null = cookieService.get("pb_isRequestPermissionEnabled");
      if (isRequestPermissionEnabled !== "false") {
        await this.requestPermission();
        cookieService.set("pb_isRequestPermissionEnabled", "false", 1); // 1-day expiration
        return;
      }

      const permission: NotificationPermission = Notification.permission;
      if (permission === "granted") {
        await this.checkAndUpdateSubscription();
      } else if (permission === "denied") {
        await this.unsubscribeFromPushService();
        cookieService.set("pb_isUnsubscribed", "true", 30); // Expires in 30 days
      }
    } catch (error: unknown) {
      errorService.errorHandler(`Error initializing push notification service: ${error}`);
    }
  }

  private static async checkAndUpdateSubscription(): Promise<void> {
    if (!("serviceWorker" in navigator)) {
      console.warn("Service workers are not supported in this browser.");
      return;
    }

    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        await this.registerServiceWorker();
      } else {
        const subscription: PushSubscription | null = await registration.pushManager.getSubscription();

        if (!subscription) {
          await this.subscribeToPushService();
          return;
        }

        const existingAppServerKey = subscription.options.applicationServerKey
          ? this.arrayBufferToBase64(subscription.options.applicationServerKey)
          : null;

        const expectedAppServerKey = this.publicKey
          ? this.arrayBufferToBase64(this.urlB64ToUint8Array(this.publicKey))
          : null;

        if (existingAppServerKey === expectedAppServerKey) {
          const cookieService: CookieService = CookieService.getInstance();
          const storedSubscription: string | null = cookieService.get("pb_isPushSubscribed");

          if (!storedSubscription) {
            CookieService.getInstance().set("pb_isPushSubscribed", "true", 3); // Expires in 3 days
            await this.subscribeToPushService();
          }
        } else {
          await subscription.unsubscribe();
          await this.subscribeToPushService();
        }
      }
    } catch (error) {
      errorService.errorHandler(`Error checking/updating push subscription: ${error}`);
    }
  }

  private static async registerServiceWorker(): Promise<ServiceWorkerRegistration | null> {
    try {
      const registration: ServiceWorkerRegistration = await navigator.serviceWorker.register(`${this.swPath}${this.getQueryString()}`);

      if (registration.waiting) {
        console.log("New service worker waiting. Sending skipWaiting message...");
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      return registration;
    } catch (error) {
      console.error("Service worker registration failed:", error);
      return null;
    }
  }

  private static arrayBufferToBase64(buffer: ArrayBuffer | null): string {
    if (!buffer) {
      return "";
    }
    const byteArray: Uint8Array = new Uint8Array(buffer);
    let binaryString = "";
    byteArray.forEach((byte) => {
      binaryString += String.fromCharCode(byte);
    });
    return btoa(binaryString);
  }

  private static urlB64ToUint8Array(base64String: string): Uint8Array {
    const padding: string = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64: string = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData: string = window.atob(base64);
    const outputArray: Uint8Array = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  public static async requestPermission(): Promise<void> {
    try {
      const permission: NotificationPermission = await Notification.requestPermission();

      if (permission === "granted") {
        this.checkAndUpdateSubscription();
      } else {
        await this.unsubscribeFromPushService();
      }
    } catch (error: unknown) {
      errorService.errorHandler(`Error requesting notification permission: ${error}`);
    }
  }

  private static async subscribeToPushService(): Promise<void> {
    const registration = await this.registerServiceWorker();
    if (!registration || !this.publicKey) {
      console.warn("PushManager or publicKey is not available.");
      return;
    }

    try {
      const subscription: PushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlB64ToUint8Array(this.publicKey),
      });

      localStorage.setItem('pb-pushSubscription', JSON.stringify(subscription));
      CookieService.getInstance().set("pb_pushNotifications", "enabled", 1);
      await this.sendSubscriptionToServer(subscription);
    } catch (error: unknown) {
      errorService.errorHandler(`Error on push subscription: ${error}`);
    }
  }

  private static getBrowserInfo(): { userAgentString: string; isMobileDevice: boolean; browser: string; platform: string } {
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);
    const browser = (() => {
      if (/Edg/i.test(userAgent)) return "Microsoft Edge";
      if (/OPR|Opera/i.test(userAgent)) return "Opera";
      if (/Chrome/i.test(userAgent)) return "Chrome";
      if (/Safari/i.test(userAgent)) return "Safari";
      if (/Firefox/i.test(userAgent)) return "Firefox";
      if (/MSIE|Trident/i.test(userAgent)) return "Internet Explorer";
      return "Unknown Browser";
    })();

    const platform = (() => {
      if (/Windows NT/i.test(userAgent)) return "Windows";
      if (/Macintosh|MacIntel|Mac/i.test(userAgent)) return "macOS";
      if (/Linux/i.test(userAgent) && !/Android/i.test(userAgent)) return "Linux";
      if (/Android/i.test(userAgent)) return "Android";
      if (/iPhone|iPad|iPod/i.test(userAgent)) return "iOS";
      if (/CrOS/i.test(userAgent)) return "Chrome OS";
      if (/SmartTV|TV/i.test(userAgent)) return "Smart TV";
      return "Unknown Platform";
    })();

    return { userAgentString: userAgent, isMobileDevice, browser, platform };
  }

  public static async sendSubscriptionToServer(subscription: PushSubscription): Promise<void> {
    const contactId: string = localStorageService.get("websites").contactId || "00000000-0000-0000-0000-000000000000";
    const accountId: string = localStorageService.get("websites").accountId || "00000000-0000-0000-0000-000000000000";
    const leadId: string = localStorageService.get("websites").leadId || "00000000-0000-0000-0000-000000000000";
    const visitorId: string = localStorageService.get("websites").personId || "";

    const browserInfo = {
      userAgentString: this.getBrowserInfo().userAgentString,
      isMobileDevice: this.getBrowserInfo().isMobileDevice,
      browser: this.getBrowserInfo().browser,
      platform: this.getBrowserInfo().platform
    };

    const payload = {
      subscription: {
        endpoint: subscription.endpoint,
        keys: {
          p256dh: this.arrayBufferToBase64(subscription.getKey("p256dh")),
          auth: this.arrayBufferToBase64(subscription.getKey("auth")),
        },
      },
      browserInfo,
      contactId,
      leadId,
      accountId,
      visitorId
    };

    this.sendEventToServer(`${this.apiUrl}/subscribe`, payload);
  }

  private static async sendEventToServer(endpoint: string, payload: object): Promise<void> {
    const xFunctionKey: string = this.pobucaKey || "";
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "x-functions-key": xFunctionKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }
    } catch (error: unknown) {
      errorService.errorHandler(`Error sending event to server: ${error}`);
    }
  }

  public static async unsubscribeFromPushService(): Promise<void> {
    try {
      const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration();
      if (!registration) return;

      let subscription: PushSubscription | null = await registration.pushManager.getSubscription();

      if (!subscription) {
        const storedSubscription: string | null = localStorage.getItem('pb-pushSubscription');
        if (storedSubscription) {
          subscription = JSON.parse(storedSubscription) as PushSubscription;
        } else {
          return;
        }
      }
      await this.removeSubscriptionFromServer(subscription);
      await subscription.unsubscribe();

      localStorage.removeItem('pb-pushSubscription');
      CookieService.getInstance().remove('pb_pushNotifications');
      CookieService.getInstance().set("pb_isUnsubscribed", "true", 30);
    } catch (error: unknown) {
      errorService.errorHandler(`Error unsubscribing from push notifications: ${error}`);
    }
  }

  private static async removeSubscriptionFromServer(subscription: PushSubscription): Promise<void> {
    const payload = {
      endpoint: subscription.endpoint,
    };

    try {
      const xFunctionKey: string = this.pobucaKey || "";
      const response: Response = await fetch(`${this.apiUrl}/unsubscribe`, {
        method: "POST",
        headers: {
          "x-functions-key": xFunctionKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }

      localStorage.removeItem('pb-pushSubscription');
    } catch (error: unknown) {
      errorService.errorHandler(`Error removing subscription from server: ${error}`);
    }
  }

  public static async anonymizeVisitor(): Promise<void> {
    try {
      const storedSubscription: string | null = localStorage.getItem('pb-pushSubscription');

      if (!storedSubscription) {
        console.warn("No subscription data found to anonymize.");
        return;
      }

      const subscription: PushSubscription = JSON.parse(storedSubscription);
      const endpoint: string = subscription.endpoint;

      const payload = { endpoint };

      const xFunctionKey: string = this.pobucaKey || "";

      const response: Response = await fetch(`${this.apiUrl}/anonymize`, {
        method: "POST",
        headers: {
          "x-functions-key": xFunctionKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }

      localStorage.removeItem('websites');

      const cookieService: CookieService = CookieService.getInstance();
      cookieService.remove('pb_pushNotifications');
    } catch (error: unknown) {
      errorService.errorHandler(`Error anonymizing visitor data: ${error}`);
    }
  }

  public static async trackPushEvent(trackingInfo: string, action: string, event: string): Promise<void> {
    const browserInfo = {
      userAgentString: this.getBrowserInfo().userAgentString,
      isMobileDevice: this.getBrowserInfo().isMobileDevice,
      browser: this.getBrowserInfo().browser,
      platform: this.getBrowserInfo().platform
    };

    const payload = {
      trackingInfo,
      action,
      event,
      browserInfo
    };

    this.sendEventToServer(`${this.apiUrl}/event`, payload);
  }

  public static async updateSubscriptionWithContactId(contactId: string): Promise<void> {
    try {
      const registration = await navigator.serviceWorker.getRegistration(this.swPath);
      if (!registration) {
        console.warn("Service worker registration not found.");
        return;
      }

      let subscription: PushSubscription | null = await registration.pushManager.getSubscription();

      if (!subscription) {
        const storedSubscription: string | null = localStorage.getItem('pb-pushSubscription');
        if (storedSubscription) {
          subscription = JSON.parse(storedSubscription) as PushSubscription;
        } else {
          console.warn("No existing subscription found to update.");
          return;
        }
      }

      // Update subscription data with the new contactId
      const payload = {
        subscription: {
          endpoint: subscription.endpoint,
          keys: {
            p256dh: this.arrayBufferToBase64(subscription.getKey("p256dh")),
            auth: this.arrayBufferToBase64(subscription.getKey("auth")),
          },
        },
        browserInfo: {
          userAgentString: this.getBrowserInfo().userAgentString,
          isMobileDevice: this.getBrowserInfo().isMobileDevice,
          browser: this.getBrowserInfo().browser,
          platform: this.getBrowserInfo().platform,
        },
        contactId,
        leadId: localStorageService.get("websites").leadId || "00000000-0000-0000-0000-000000000000",
        accountId: localStorageService.get("websites").accountId || "00000000-0000-0000-0000-000000000000",
        visitorId: localStorageService.get("websites").personId || "",
      };

      await this.sendEventToServer(`${this.apiUrl}/subscribe`, payload);
    } catch (error: unknown) {
      errorService.errorHandler(`Error updating subscription with contactId: ${error}`);
    }
  }

}

export default PushNotificationService;

