import { RequestOptions } from "../models/interfaces/RequestOptions";
import { HttpMethods } from "../models/types/Http";
import { getTrackingApiUrl } from "../utilities/tracking-api.utility";
import errorService from "./error.service";

const BASE_URL: string = getTrackingApiUrl();

class ApiService {
    private static instance: ApiService;

    private headers: any = {
        "Content-Type": "application/json",
        "x-functions-key": ""
    };

    constructor() {
        if (ApiService.instance) return ApiService.instance;
        ApiService.instance = this;
    }

    private async request(method: HttpMethods, path: string, data: unknown = {}, headers = this.headers): Promise<Response> {
        const options: RequestOptions = {
            method,
            headers,
            ...(method !== "GET" && { body: JSON.stringify(data) })
        };
        try {
            const response: Response = await fetch(`${BASE_URL}/${path}`, options);
            return response;
        } catch (error: unknown) {
            errorService.errorHandler(error);
            throw error;
        }
    }

    public addCustomHeaderOption(headerCustomOption: any): void {
        Object.assign(this.headers, headerCustomOption);
    }

    public get(path: string, headers: HeadersInit = this.headers): Promise<any> {
        return this.request("GET", path, undefined, headers);
    }

    public put(path: string, data: any = {}, headers: HeadersInit = this.headers): Promise<any> {
        return this.request("PUT", path, data, headers);
    }

    public post(path: string, data: any = {}, headers: HeadersInit = this.headers): Promise<any> {
        return this.request("POST", path, data, headers);
    }

    public delete(path: string, headers: HeadersInit = this.headers): Promise<any> {
        return this.request("DELETE", path, undefined, headers);
    }
}

export default new ApiService();
