import { SessionInfo } from "../../models/interfaces/SessionInfo";
import sessionService from "./session.service";
import visitorService from "./visitor.service";
import localStorageService from "../local-storage.service";
import errorService from "../error.service";
import { WebsitesState } from "../../models/interfaces/WebsitesState";
import loginService from "../events/login.service";
import { TargetGroup } from "../../models/types/TargetGroup";
import CookiesConsentService from "../cookies/cookies-consent.service";

class WebsitesStateService {

  async groupLocalStorage() {
    try {
      const personId: string = visitorService.getPersonId();
      const contactId: string | null = CookiesConsentService.isTrackingAllowed() ? loginService.getContactId() : null;
      const sessionInfo: SessionInfo = sessionService.getSessionInfo() || sessionService.createNewSession();
      const isSessionExpired: boolean = sessionService.isSessionExpired(sessionInfo.lastEventTimeStamp);
      const personType: TargetGroup = visitorService.getPersonType(isSessionExpired);
      sessionService.updateSessionInfo(sessionInfo);

      const groupData: WebsitesState = {
        personId,
        personType,
        contactId,
        sessionInfo: {
          sessionId: sessionInfo.sessionId,
          lastEventTimeStamp: sessionInfo.lastEventTimeStamp,
          sessionPages: sessionInfo.sessionPages,
          visitedUrls: sessionInfo.visitedUrls,
        },
      };
      localStorageService.save("websites", groupData);
    } catch (error: unknown) {
      errorService.errorHandler(`Error consolidating local storage, ${error}`);
    }
  }
}

export default new WebsitesStateService();
