import { Popup } from "../../models/interfaces/Popup";
import { PopupsState } from "../../models/interfaces/PopupsState";
import apiService from "../api.service";
import errorService from "../error.service";
import configurationService from "../initialization/configuration.service";
import localStorageService from "../local-storage.service";
import visitorService from "../initialization/visitor.service";
import { DeviceType } from "../../models/types/Device";
import { TargetGroup } from "../../models/types/TargetGroup";
import { detectDeviceType } from "../../utilities/device.utility";
import { getDate } from "../../utilities/date.utility";

class FetchPopupService {
  private fetchInterval: number = 10 * 60 * 1000; // 10 minutes

  public async getPopups(deviceType: DeviceType): Promise<Popup[]> {
    const storedPopupsState: PopupsState | null = localStorageService.get("fetched-popups");
    const currentTime: number = new Date(getDate()).getTime();

    if (storedPopupsState && currentTime - new Date(storedPopupsState.timestamp).getTime() < this.fetchInterval) {
      return storedPopupsState.popups;
    }

    const popups: Popup[] = await this.fetchPopupData(deviceType);

    localStorageService.save("fetched-popups", { popups, timestamp: getDate(), isPopupRefreshed: false });

    return popups;
  }

  public async fetchPopupData(deviceType: DeviceType): Promise<Popup[]> {
    try {
      const pobucaKey: string | null = configurationService.getInstance().getPobucaKey();
      const headers: any = {
        "x-functions-key": pobucaKey
      };
      const personType: TargetGroup = visitorService.getPersonType(false);
      const response: Response = await apiService.get("Popup", headers);
      const popupData: Popup[] = await response.json();
      const filteredPopups: Popup[] = popupData.filter((popup) =>
        popup.Settings.targetGroups.includes(personType) &&
        popup.Settings.supportedDevices.includes(deviceType)
      );

      localStorageService.save("fetched-popups", { popups: filteredPopups, timestamp: getDate(), isPopupRefreshed: true });
      return filteredPopups;
    } catch (error: unknown) {
      errorService.errorHandler(`Error fetching popup data, ${error}`);
      throw error;
    }
  }

  public setupPopupRefresh(): void {
    setInterval(async () => {
      const deviceType: DeviceType = detectDeviceType();
      const popups: Popup[] = await this.fetchPopupData(deviceType);
      localStorageService.save("fetched-popups", { popups, timestamp: getDate(), isPopupRefreshed: true });
    }, this.fetchInterval);
  }
}

export default FetchPopupService;
