import { ClickEvent, ExitEvent, LoadEvent, ScrollEvent } from "../../models/interfaces/Events";
import { Popup } from "../../models/interfaces/Popup";
import { TrackingPopups } from "../../models/interfaces/TrackingPopups";
import { CustomEvent } from "../../models/types/Event";
import { calculateScrollTriggerPoint } from "../../utilities/scroll.utility";
import sessionService from "../initialization/session.service";
import TrackingService from "../tracking.service";
import displayPopupService from "./display-popup.service";
import managePopupService from "./manage-popup.service";
import popupStatisticsService from "./popup-statistics.service";
import priorityStateService from "./priority-state.service";

class TriggerPopupService {
  private shownPopups: Map<string, boolean>;

  constructor(private trackingService: TrackingService) {
    this.shownPopups = new Map();
  }

  private getPopupEventKey(popupId: string, eventType: string): string {
    return `${popupId}-${eventType}`;
  }

  private markPopupAsShown(popupId: string, eventType: string): void {
    this.shownPopups.set(this.getPopupEventKey(popupId, eventType), true);
  }

  private isPopupShown(popupId: string, eventType: string): boolean {
    return this.shownPopups.get(this.getPopupEventKey(popupId, eventType)) === true;
  }

  public handleLoadEvent(event: LoadEvent, popup: Popup, sessionId: string, personId: string, personType: string): void {
    let isPopupShown: boolean = false;
    if (!isPopupShown) {
      isPopupShown = true;
      setTimeout(() => {
        isPopupShown = true;
        this.trackPopupsAndDisplay(popup, sessionId, personId, personType, event);
      }, event.delayInSeconds * 1000);
    }
  }

  public handleClickEvent(event: ClickEvent, popup: Popup, sessionId: string, personId: string, personType: string): void {
    if (this.isPopupShown(popup.Id, "ClickEvent")) return;
    const element: Element | null = document.querySelector(event.selector);
    if (element) {
      const clickHandler = () => {
        this.markPopupAsShown(popup.Id, "ClickEvent");
        setTimeout(() => {
          this.trackPopupsAndDisplay(popup, sessionId, personId, personType, event);
        }, event.delayInSeconds * 1000);
      };
      element.addEventListener("click", clickHandler);
    }
  }

  public handleScrollEvent(event: ScrollEvent, popup: Popup, sessionId: string, personId: string, personType: string): void {
    if (this.isPopupShown(popup.Id, "ScrollEvent")) return;
    const triggerPoint: number = calculateScrollTriggerPoint(event.yOffset, event.yOffsetType);
    const onScroll = () => {
      if (window.scrollY >= triggerPoint && !this.isPopupShown(popup.Id, "ScrollEvent")) {
        this.markPopupAsShown(popup.Id, "ScrollEvent");
        setTimeout(() => {
          this.trackPopupsAndDisplay(popup, sessionId, personId, personType, event);
          window.removeEventListener("scroll", onScroll);
        }, event.delayInSeconds * 1000);
      }
    };
    window.addEventListener("scroll", onScroll, { passive: true });
  }

  public handleExitEvent(event: ExitEvent, popup: Popup, sessionId: string, personId: string, personType: string): void {
    if (this.isPopupShown(popup.Id, "ExitEvent")) return;
    const showPopup = (): void => {
      this.markPopupAsShown(popup.Id, "ExitEvent");
      setTimeout(() => {
        this.trackPopupsAndDisplay(popup, sessionId, personId, personType, event);
      }, event.delayInSeconds * 1000);
    };
    const onMouseLeave = (mouseEvent: MouseEvent): void => {
      if (mouseEvent.clientY <= 0 && !this.isPopupShown(popup.Id, "ExitEvent")) {
        showPopup();
        document.removeEventListener("mouseleave", onMouseLeave);
      }
    };
    document.addEventListener("mouseleave", onMouseLeave);
  }

  private trackPopupsAndDisplay(
    popup: Popup,
    sessionId: string,
    personId: string,
    personType: string,
    event: CustomEvent | any
  ): void {
    if (displayPopupService.isActive()) return;
    if (!managePopupService.canShowPopup(popup)) return;
    displayPopupService.displayPopup(popup, personId, sessionId, personType);
    sessionService.updateLastEventTimestamp();
    const updatedStatistics: TrackingPopups = popupStatisticsService.updatePopupStatistics(popup, event, sessionId);
    this.trackingService.trackEvents(updatedStatistics);

    //Updating Priority State
    priorityStateService.addPriorityEntry(event.type, popup.Priority ?? 0);
  }
}

export default TriggerPopupService;
